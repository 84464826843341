@import "../../../style/variables";

.BlockActivityInfo{
  .btn-filters{
    color: $primary-dark;
    font-weight: bold;
    background-color: #FFF;
    border-radius: 10px;
    width: 150px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .btn-filters:hover{
    color: $primary;
    font-weight: bold;
    background-color: #f8f9fa;
    border-radius: 10px;
    width: 150px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

}
