@import "../../../style/variables";

.ExhibitorModal{

  font-size: 15px;

  .img-exhibitor{
    border: 4px solid $primary;
  }

  .description-exhibitor{
    color:#828282;
  }

  .button-gradient{
    background: linear-gradient(180deg, #00EB5E 0%, #00AE84 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border:none;
    color:#FFF;
    width: 100px;
  }

}
