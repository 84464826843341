@import "../../../style/_variables.scss";

.block-functionality{
  font-family: $font-family-main;
  text-align: center;
  background: $green-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  p{
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  img {
    width: 18px;
  }

}
