.BlockEventCarousel{
  .carousel-item{
    height: 350px;
  }

  .modal-image {
    height: 500px;

  }

  .carousel-item img {
    height: 350px;
    width: auto !important;
    margin: auto;
  }

  .modal-content {
    width: auto !important;
  }
}
