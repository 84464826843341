.uninstall-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    color: #333;
    text-align: center;
  }
  
  h2 {
    color: #555;
  }
  
  h3 {
    color: #777;
  }
  
  section {
    margin-bottom: 20px;
  }
  
  p {
    line-height: 1.6;
  }