@import "../../../style/_variables.scss";


.charger-carousel {
  width: 400px;
  padding-bottom: 50px;
  img {
    width: auto !important;
    display: inline-block !important;
  }
  .carousel-indicators {
    li {
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
  }
}

@media (max-width: $medium) {
  .charger-carousel {
    width: 350px;
    margin-top:10px;
    img {
      width: 260px !important;
      display: inline-block !important;
    }
  }
  .carousel-indicators {
    li {
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
  }
}


@media (max-width: $small) {
  .charger-carousel {
    width: 350px;
    margin-top:10px;
    img {
      width: 260px !important;
      display: inline-block !important;
    }
  }
  .carousel-indicators {
    li {
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
  }
}
