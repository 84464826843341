@import "../../style/variables";

.navbar-wroom {

  height: $topBarHeight;
  @media (max-width: $medium) {
    height: unset;
  }

  .navbar-brand {
    img {
      height: 38px;
    }
  }

  .navbar-nav {
    .nav-item a {
      font-size: 22px;
      font-family: $font-family-topbar;
      padding-left: 0.2em;
      color: $white;
      &:hover {
        color: $white;
        text-decoration: underline;
      }
      &.active {
        color: $white;
        text-decoration: underline;
      }
    }
  }

  .top-logo {
      min-width: 200px;
  }

  .social-icon {
      min-width: 26px;
  }
}
