.BlockCarouselActivity{
  .carousel-item{
    height: 450px;
  }

  .carousel-item img {
    height: 450px;
    width: auto !important;
    margin: auto;
  }

}
