@import "../../../style/_variables.scss";

.BlockNewChargers{
  .news{
    img {
      height: 250px;
      border: 3px solid $primary;
      border-radius: 10px;
    }
  }
}
