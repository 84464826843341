@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.block-activities-evidence{

.activity-block-evidence{
  background-color: #000;
  position: relative;
  img {
    opacity: 0.5;
  }
}

a:hover{
  text-decoration: none;
}

.activity-block{
  margin-top: 10px;
  position: relative;
  border-radius: 10px;
  margin-right: 20px;
  margin-left: 20px;
  max-height: 250px;
  background-repeat: no-repeat;
  min-height: 250px;
  flex-direction: column;
  background-size: cover;
  background-color: #000;

  .caption {
      position: absolute;
      bottom:0px;
      padding-left: 10px;
      padding-top: 60px;
      width: 100%;
      text-align: left;
      height: 100px;
      border-radius: 10px;
      background: -moz-linear-gradient(top,  rgba(137,255,241,0) 0%, rgba(0,0,0,1) 100%); /* FF3.6+ */
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(137,255,241,0)), color-stop(100%,rgba(0,0,0,1))); /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(top,  rgba(137,255,241,0) 0%,rgba(0,0,0,1) 100%); /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(top,  rgba(137,255,241,0) 0%,rgba(0,0,0,1) 100%); /* Opera 11.10+ */
      background: -ms-linear-gradient(top,  rgba(137,255,241,0) 0%,rgba(0,0,0,1) 100%); /* IE10+ */
      background: linear-gradient(to bottom,  rgba(137,255,241,0) 0%,rgba(0,0,0,1) 100%); /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0089fff1', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
  }
}

.activity-block-logo {
  height: 80px;
  width: 80px;
  border-radius: 40px;
  margin-top: 10px;
  margin-left: 10px;
}

.slick-next::before {
  background: url('/images/right.png') no-repeat;
  content: "";
  display: block;
  height: 33px;
  width: 20px;
}

.slick-next, .slick-prev{
  top:125px;
}

.slick-prev::before {
  background: url('/images/left.png') no-repeat;
  content: "";
  display: block;
  height: 33px;
  width: 20px;
}

.caption {
    position: absolute;
    top: 60%;
    left: 0;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}


}
