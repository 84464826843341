@import "../../../style/variables";

.block-events{
  .event-block{
    margin-top: 30px;
    position: relative;
    border-radius: 10px;
    max-height: 350px;
    background-repeat: no-repeat;
    min-height: 350px;
    flex-direction: column;
    background-size: cover;
    background-color: #000;

    .caption {
        position: absolute;
        bottom:0px;
        padding-left: 10px;
        padding-top: 90px;
        width: 100%;
        text-align: left;
        height: 200px;
        border-radius: 10px;
        background: -moz-linear-gradient(top,  rgba(137,255,241,0) 0%, rgba(0,0,0,1) 100%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(137,255,241,0)), color-stop(100%,rgba(0,0,0,1))); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top,  rgba(137,255,241,0) 0%,rgba(0,0,0,1) 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top,  rgba(137,255,241,0) 0%,rgba(0,0,0,1) 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(top,  rgba(137,255,241,0) 0%,rgba(0,0,0,1) 100%); /* IE10+ */
        background: linear-gradient(to bottom,  rgba(137,255,241,0) 0%,rgba(0,0,0,1) 100%); /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0089fff1', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
    }

    i{
      margin-right: 30px;
      padding:5px;
    }


  }

  .btn-filters{
    color: $primary-dark;
    font-weight: bold;
    background-color: #FFF;
    border-radius: 10px;
    width: 150px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .btn-filters:hover{
    color: $primary;
    font-weight: bold;
    background-color: #f8f9fa;
    border-radius: 10px;
    width: 150px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .text-word {
    height: 80px;
    overflow: hidden;
  }

}
