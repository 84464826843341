//overriding bs example
$primary: #00eb5e; //verdino
$light: rgb(238, 238, 238); //grigio
$secondary: rgb(217, 159, 91); //oro
$primary-dark: #00AE84;

$green-gradient: linear-gradient(180deg, #00EB5E 0%, #00AE84 100%);
// $success: #47DAA2;
// $danger: #EB0017;
// $warning: #FCBE54;
// $dark: #434343;

$black: #222;
$white: #fff;
$gray: rgb(112, 112, 112);

$font-family-main: Ubuntu;
$font-family-topbar: Gloria Hallelujah;

// padding
$pad-10: 10px;
$pad-20: 20px;

// bootstrap grid
$small: 576px;
$medium:768px;
$large: 992px;
$x-large: 1200px;

$topBarHeight: 132px;
$topBarHeightMobile: 64px;
