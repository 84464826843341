.block-promotions{
  .activity-block{
    margin-top: 25px;
    position: relative;
    border-radius: 10px;
    max-height: 250px;
    background-repeat: no-repeat;
    min-height: 250px;
    flex-direction: column;
    background-size: cover;
    background-color: #000;

    .caption {
        position: absolute;
        bottom:0px;
        padding-left: 10px;
        padding-top: 60px;
        width: 100%;
        text-align: left;
        height: 150px;
        border-radius: 10px;
        background: -moz-linear-gradient(top,  rgba(137,255,241,0) 0%, rgba(0,0,0,1) 100%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(137,255,241,0)), color-stop(100%,rgba(0,0,0,1))); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top,  rgba(137,255,241,0) 0%,rgba(0,0,0,1) 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top,  rgba(137,255,241,0) 0%,rgba(0,0,0,1) 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(top,  rgba(137,255,241,0) 0%,rgba(0,0,0,1) 100%); /* IE10+ */
        background: linear-gradient(to bottom,  rgba(137,255,241,0) 0%,rgba(0,0,0,1) 100%); /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0089fff1', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
    }

    i{
      margin-right: 30px;
      padding:5px;
    }


  }
}
