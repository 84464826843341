@import "../../../style/variables";

.BlockFilters{
  i {
    border: 1px solid #FFF;
    width: 38px;
    height: 38px;
    background-color: #FFF;
    color: $primary;
    border-radius: 50%;
    text-align: center;
    line-height: 38px !important;
    margin-bottom: 10px;
    vertical-align: middle;
    z-index: 20;
    cursor: pointer;
  }

  .search{
    background: url('/images/search.png') no-repeat scroll 10px 7px;
    padding-left:40px;
    background-color: #FFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  i:hover{
    background-color: $primary;
    color: #FFF;
  }

  i.active{
    background-color: $primary;
    color: #FFF;
  }

  .btn-filters{
    color: $primary-dark;
    font-weight: bold;
    background-color: #FFF;
    border-radius: 10px;
    width: 150px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .btn-filters:hover{
    color: $primary;
    font-weight: bold;
    background-color: #f8f9fa;
    border-radius: 10px;
    width: 150px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .middle-line-filters{
    position: absolute;
    background: $white;
    left: 0;
    right: 38px;
    top: 19px;
    height: 2px;
    z-index: 0;
  }
}
