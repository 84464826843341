@import "../../../style/variables";

.btn-filters{
  color: $primary-dark;
  font-weight: bold;
  background-color: #FFF;
  border-radius: 10px;
  width: 150px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
