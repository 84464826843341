@import "../../../style/variables";

.PromotionModal{

  font-size: 15px;

  .img-promotion{
    border: 4px solid $primary;
  }

  .description-promotion{
    color:#828282;
  }

  .image-like-icon {
    width: 100px;
    height: 100px;
    border-radius: 50px;
  }

}
