@import "../../../style/_variables.scss";


.home-carousel {
  width: 400px;
  padding-bottom: 50px;
  img {
    width: auto !important;
    display: inline-block !important;
  }
  .carousel-indicators {
    li {
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
  }
}

@media (max-width: $medium) {
  .home-carousel {
    width: 350px;
  }
}


@media (max-width: $medium) {
  .home-carousel {
    width: 350px;
  }
}
