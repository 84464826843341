@import "../../style/variables";

.react-cookie-banner {
  position: fixed !important;
  bottom: 0;
  height: auto !important;
  text-align: center !important;
  padding: 20px 20px !important;
  background: $white !important;
  border-top: solid $primary 2px;

  .cookie-message {
    margin-right: 60px !important;
    display: block;
    line-height: 16px !important;
    font-weight: normal !important;
    font-size: 14px;
    display: inline-block !important;
    margin: 0 !important;
    color: $black !important;

    a {
      color: $primary !important;
    }
  }

  .button-close{
    background: $primary !important;
    color: $white !important;
    display: block !important;
    position: static !important;
    right: 0px;
    margin: 10px auto !important;
    width: auto !important;
    padding: 4px !important;
    width: 100px !important;

    // right: 10px !important;
    // top: 40% !important;

  }



}
