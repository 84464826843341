.SearchStations{
    top:40px;
    left:40px;
    z-index: 30;

    input {
      display: block;
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background: url('/images/search.png') no-repeat scroll 10px 7px;
      padding-left:40px;
      background-color: #FFF;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    input:active{
      border: 1px solid green;
    }

    .search{

    }
}
