@import "../../style/variables";

.active-filter{
  background: linear-gradient(180deg, #00EB5E 0%, #00AE84 100%);
  color:#FFF;
}

.popover-body{
  height: 220px;
  overflow-y: scroll;
}

.popover-body{
  padding: 0px;
  div{
     border-bottom: 1px dashed #CCC;
     font-weight: bold;
     padding-top: 3px;
     padding-bottom: 3px;
     padding-left: 5px;
     padding-right: 5px;
     font-size: 12px;
  }
}

.close-pop{
  position: absolute;
  top: -10px;
  right:-10px;
}

.Mappa{
  .filters{
    bottom:0px;
    background:rgba(255,255,255, 0.7);
    z-index: 30;
    width: 100%;
    height: 90px;
  }

.filter{
  border: 3px solid $primary;
  width: 60px;
  height: 60px;
  color: #FFF;
  border-radius: 50%;
  text-align: center;
  line-height: 50px !important;
  vertical-align: middle;
  z-index: 20;
  cursor: pointer;
}

.filters-label{
  margin-top:10px;
  margin-left: 10px;
  margin-right: 10px;
  line-height: 10px;
  font-size: 12px;
  font-weight: bold;
}

.filter img{
  vertical-align: middle;
}

.active{
  background-color: $primary;
}

  i {
    border: 1px solid #FFF;
    width: 100px;
    height: 100px;
    background-color: #FFF;
    color: #FFF;
    background-color: $primary;
    border-radius: 50%;
    text-align: center;
    line-height: 100px !important;
    vertical-align: middle;
    z-index: 20;
    cursor: pointer;
  }

  i:hover{
    background-color: $primary;
    color: #FFF;
  }

  .invia-button{
    background: linear-gradient(180deg, #00EB5E 0%, #00AE84 100%);
    border-radius: 5px;
    border:none;
    color:#FFF;
    width: 70px;
  }





}
