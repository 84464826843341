@import '../../style/variables';

.MapWroom {
  height:  100%;

  .info-window-content {
    background-color: $white !important;
  }

  .info-window-content-arrows > div > div {
    background-color: $white !important;
  }



}
