@import "../../style/variables";

.Contatti{
  .contacts-map-container {
    flex: 2;
    // min-height: 300px;
    pointer: default!important;
    @media(max-width: $medium){
      height: 400px;
    }
  }

  .text-red{
    color: red;
  }

  .block-opening-hours{
    z-index: 90;
    width: 25%;
    height: 100%;
  }

  .table-responsive{
    font-size: 14px;
  }

  .invia-button{
    background: linear-gradient(180deg, #00EB5E 0%, #00AE84 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border:none;
    color:#FFF;
    width: 100px;
  }

}
